<template>
  <div>
    <v-btn-toggle v-if="index !== -1">
      <v-dialog
        v-model="editDialogVisible"
        width="60vw"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-toolbar>
            <div class="d-flex">
              <v-text-field
                class="v-input-dense align-center"
                solo
                outlined
                hide-details
                v-model="section.title"
              >
              </v-text-field>
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn color="primary" text @click="saveContent">
                Speichern
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <vue-editor
            v-model="section.content"
            :editorToolbar="customToolbar"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="deleteDialogVisible"
        width="30vw"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">
            Sektion wirklich löschen?
          </v-card-title>
          <v-card-text>
            Durch Bestätigen wird die Sektion und alle enthaltenen Inhalte
            permanent gelöscht.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="
                deleteDialogVisible = false;
                deleteSection();
              "
            >
              Löschen
            </v-btn>
            <v-btn color="primary" @click="deleteDialogVisible = false">
              Abbrechen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-btn-toggle>
    <v-dialog
      v-model="editDialogVisible"
      width="60vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="index == -1" v-bind="attrs" v-on="on">
          <span>
            Neue Sektion
            <v-icon> mdi-plus</v-icon>
          </span>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar>
          <div class="d-flex">
            <v-text-field
              class="v-input-dense align-center"
              solo
              outlined
              hide-details
              v-model="section.title"
            >
            </v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="primary" text @click="saveContent"> Speichern </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <vue-editor v-model="section.content" :editorToolbar="customToolbar" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { VueEditor } from "vue2-editor";
export default Vue.extend({
  name: "PraxisEditorDialog",

  components: { VueEditor },
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      editDialogVisible: false,
      deleteDialogVisible: false,
      customToolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
      section: { title: this.title, content: this.content },
    };
  },
  methods: {
    saveContent() {
      this.$emit("saveContent", this.section, this.index);
      this.dialogVisible = false;
    },
    deleteSection() {
      this.$emit("deleteSection", this.index);
    },
  },
});
</script>
