<template>
  <div>
    <v-row
      v-for="section in profile.sections"
      :key="section.id"
      class="justify-center ma-2 pa-2"
    >
      <v-col cols="12" md="7">
        <v-card>
          <v-card-title>
            <v-icon large left> {{ section.icon }} </v-icon>
            <span class="title font-weight-light">
              {{ section.title }}
            </span>
          </v-card-title>
          <v-card-text>
            <span v-html="section.content" style="white-space: pre-line">
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="1">
        <PraxisEditorDialog
          :title="section.title"
          :content="section.content"
          :index="section.id"
          @saveContent="saveContent"
          @deleteSection="deleteSection"
        />
      </v-col>
    </v-row>
    <v-row class="justify-center my-6">
      <PraxisEditorDialog
        title=""
        content=""
        :index="-1"
        @saveContent="saveContent"
      />
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import PraxisEditorDialog from "@/components/PraxisEditorDialog.vue";

export default Vue.extend({
  name: "PraxisEditor",

  components: {
    PraxisEditorDialog,
  },

  data() {
    return {
      profile: {},
    };
  },
  mounted() {
    this.updateProfile();
  },
  methods: {
    updateProfile() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/mine",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.sections = result.sections.sort((a, b) => {
            return a.id - b.id;
          });
          Vue.set(this, "profile", result);
        })
        .catch((error) => console.log("error", error));
    },
    saveContent(section, index) {
      if (index === -1) {
        this.addSection(section.title, section.content);
      } else {
        this.updateSection(section.title, section.content, index);
      }
    },
    updateSection(title, content, index) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        title: title,
        content: content,
        icon: "mdi-pencil-outline",
        id: index,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/section`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.sections = this.sortSections(result.sections);
          Vue.set(this, "profile", result);
        })
        .catch((error) => console.log("error", error));
    },
    addSection(title, content) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        title: title,
        content: content,
        icon: "mdi-pencil-outline",
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/section/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.sections = this.sortSections(result.sections);
          Vue.set(this, "profile", result);
        })
        .catch((error) => console.log("error", error));
    },
    deleteSection(id) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/section/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.sections = this.sortSections(result.sections);
          Vue.set(this, "profile", result);
        })
        .catch((error) => console.log("error", error));
    },
    sortSections(sections) {
      return sections.sort((a, b) => {
        return a.id - b.id;
      });
    },
  },
});
</script>
