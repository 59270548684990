var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.index !== -1)?_c('v-btn-toggle',[_c('v-dialog',{attrs:{"width":"60vw","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pen")])],1)]}}],null,false,3803368595),model:{value:(_vm.editDialogVisible),callback:function ($$v) {_vm.editDialogVisible=$$v},expression:"editDialogVisible"}},[_c('v-card',[_c('v-toolbar',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"v-input-dense align-center",attrs:{"solo":"","outlined":"","hide-details":""},model:{value:(_vm.section.title),callback:function ($$v) {_vm.$set(_vm.section, "title", $$v)},expression:"section.title"}})],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveContent}},[_vm._v(" Speichern ")])],1)],1),_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.section.content),callback:function ($$v) {_vm.$set(_vm.section, "content", $$v)},expression:"section.content"}})],1)],1),_c('v-dialog',{attrs:{"width":"30vw","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,3363759921),model:{value:(_vm.deleteDialogVisible),callback:function ($$v) {_vm.deleteDialogVisible=$$v},expression:"deleteDialogVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Sektion wirklich löschen? ")]),_c('v-card-text',[_vm._v(" Durch Bestätigen wird die Sektion und alle enthaltenen Inhalte permanent gelöscht. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.deleteDialogVisible = false;
              _vm.deleteSection();}}},[_vm._v(" Löschen ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.deleteDialogVisible = false}}},[_vm._v(" Abbrechen ")])],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"60vw","fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [(_vm.index == -1)?_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Neue Sektion "),_c('v-icon',[_vm._v(" mdi-plus")])],1)]):_vm._e()]}}]),model:{value:(_vm.editDialogVisible),callback:function ($$v) {_vm.editDialogVisible=$$v},expression:"editDialogVisible"}},[_c('v-card',[_c('v-toolbar',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"v-input-dense align-center",attrs:{"solo":"","outlined":"","hide-details":""},model:{value:(_vm.section.title),callback:function ($$v) {_vm.$set(_vm.section, "title", $$v)},expression:"section.title"}})],1),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveContent}},[_vm._v(" Speichern ")])],1)],1),_c('vue-editor',{attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.section.content),callback:function ($$v) {_vm.$set(_vm.section, "content", $$v)},expression:"section.content"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }